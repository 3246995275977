@import 'variables';

@mixin submit {
    display: inline;
    background: $main_color;;
    border-radius: 10px;
    width: 230px;
    height: 54px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
}


@mixin horizontalAlign {
    margin-left: 50%;
    transform: translateX(-50%);
}

@mixin input {
    width: 100%;
    height: 50px;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    background: $background_2_color;
    padding-left: 15px;
}


@mixin spinnerWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}

@mixin formAnswer {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
}

@mixin label {
    display: block;
    font-weight: 600;
    font-size: 16px;
    color: $text_2_color;
}

@mixin formButtonsChange {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 30px;
        .submitDelete {
            @include submit;
            background: $forth_color;
            width: 47%;
            display: block;
        } 
        .submitUpdate {
            @include submit;
            width: 47%;
            display: block;
        } 
}