@use "sass:map";
@import "variables";
@import "mixin";

.cardWrapper {
	container-type: size;
}

.card {
	font-size: min(14cqmin + 0.25vw, 18px);
	color: $text_1_color;
	padding: 1px;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow: auto;
	overflow-x: hidden;

	&_small {
		font-size: min(11cqmin + 0.25vw, 18px);

		@media (max-width: map.get($breakpoints, "xl")) {
			font-size: min(13cqmin + 0.25vw, 18px);
		}

		@media (max-width: map.get($breakpoints, "lg")) {
			font-size: min(14cqmin + 0.3vw, 18px);
		}

		&.class-card_standard {
			font-size: min(11cqmin + 0.3vw, 18px);

			@media (max-width: map.get($breakpoints, "lg")) {
				font-size: min(13cqmin + 0.3vw, 18px);
			}
		}

		&.class-card_small {
			@media (min-width: map.get($breakpoints, "xxl")) {
				font-size: min(10cqmin + 0.25vw, 18px);
			}
		}
	}

	&_large {
		font-size: min(17cqmin + 0.2vw, 18px);

		&.class-card_small {
			font-size: min(18cqmin + 0.25vw, 18px);

			@media (min-width: map.get($breakpoints, "xxxl")) {
				font-size: min(18cqmin + 0.2vw, 18px);
			}

			@media (max-width: map.get($breakpoints, "xl")) {
				font-size: min(18cqmin + 0.3vw, 18px);
			}
		}
	}

	&_medium {
		font-size: min(13cqmin + 0.25vw, 18px);

		&.class-card_small {
			@media (max-width: map.get($breakpoints, "lg")) {
				font-size: min(14cqmin + 0.25vw, 18px);
			}
		}
	}

	&_min-duration {
		font-size: min(10cqmin + 0.22vw, 18px);
	}

	.card__item {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		word-break: break-all;

		svg {
			width: 1em;
			height: 1em;
		}

		&:last-child {
			margin-right: 0%;
		}

		&_line-clamp {
			-webkit-line-clamp: 2;
			word-break: break-word;
		}

		&_uncut {
			overflow: auto;
			display: block;
			-webkit-line-clamp: initial;
			-webkit-box-orient: horizontal;
			word-break: normal;
		}

		&.time {
			overflow: hidden;
		}
	}

	@media (max-width: map.get($breakpoints, "lg")) {
		.card__item {
			svg {
				display: none;
			}
		}
	}
}
