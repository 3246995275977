

@import "variables";

.overlay {
    font-size: 16px;
    color: $text_1_color;
    background: rgba($color: #000000, $alpha: 0.4);
    position: absolute;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0 ;
    z-index: 5;
}


.window {
    min-width: 524px;
    min-height: 220px;    
    max-height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 56px 70px 30px 70px;
    font-weight: 600;
    font-size: 22px;
    color: #3F3F55;
    text-align: center;


}


.submit {
    display: inline;
    background: $main_color;;
    border-radius: 10px;
    width: 190px;
    height: 54px;
    margin-top: 10px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
}