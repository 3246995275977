@use "sass:map";
@import 'variables';

//@import 'bootstrap/dist/css/bootstrap.min.css';
.demo-app {
  display: flex;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #a7a7a7;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #A7A7A7FF;
  }

  thead {
    tr {
      background: #F2F3F9;
      border-width: 1px 0;
      border-style: solid;
      border-color: #D9D9D9;
    }

    th th {
      border: none;
      padding: 10px;
    }

    background: #F2F3F9;
    border-color: #D9D9D9;
  }

  tbody {
    .fc-scrollgrid-shrink {
      text-transform: uppercase;
    }

    .fc-timegrid-slot-label-frame {
      text-align: center;
      color: #979AA8;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .fc-media-screen {
    min-height: 600px;
  }
}

.additionalHead {
  border: 1px solid #d9d9d9;
  border-bottom: none;
  background-color: #FFFFFF;
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  flex-wrap: wrap;
  
}

.weekDays {
  color: #979AA8 !important;
  &:hover {
    .editDots {
      visibility: visible;
    }
  }
  .editDots {
    visibility: hidden;
  }
}

.app-sidebar {
  width: 20%;
  padding-left: 20px;
  line-height: 1.5;
}

.accordionBodyFilter {
  max-height: 200px;
  overflow-y: auto;
}

.primaryButton {
  min-width: 110px;
  max-width: 150px;
  height: 55px;
  background: #5882FF;
  border-radius: 10px;
}

.mediumSizeButton {
  height: 40px;
  width: 150px;
}

.pdfButton {
  width: 55px;
  height: 55px;
}

.filtersModal {
  width: 380px;
  position: absolute;
  top: 50px;
  left: auto;
  right: 10%;
  display: block;
  overflow: visible;

  .btn-close {
    position: absolute;
    right: 20px;
  }
}

.filterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 157px;
  height: 55px;
  font-size: 18px!important;
  text-align: center;

}

.schedulesSelectButton {
  width: 250px;
  font-size: 16px;
}

.greyButton {
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  color: #3F3F55;

  &:hover, &:active, &:focus {
    color: #3F3F55 !important;
    background: #F9F9FC !important;
    border: 1px solid #D9D9D9 !important;
  }
}

.searchResults {
  max-height: 650px;
  min-height: 500px;
  height: 80%;
  overflow-y: auto;
}

.app-main {
  width: 80%;
  position: relative;
}

.cardsPull {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.checkboxFilters {
  display: flex;
  align-items: flex-start;

  input {
    margin-right: 20px;
    padding: 2px;
    background-clip: content-box;
    width: 20px;
    height: 20px;
    border: 1px solid #5882FF;
    border-radius: 5px;
    background-image: none !important;
  }

  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

}
.resourceLabelWrapper {
  display: flex;
  align-items: center;
  padding: 9px 12px;
  border-radius: 10px;
  cursor: pointer;
  button {
    line-height: 0.8;
    display: none;
  }
  &:hover {
    color:#5882FF;
    background: #E9EDFF;
    button {
      display: inline-block;
    }
  }
}

.event-card {
  font-size: 15px;
  color: #3F3F55;
  padding-bottom: 2px;
}

.selectedOption {
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  margin: 0 0.55rem 0.55rem 0;
  font-size: 0.75rem;
  color: #5882FF;
  border-radius: 3px;
  background-color: #E5EAFE;
  border: 1px solid #5882FF;
  user-select: none;

  .closeButton {
    all: unset;
    margin-left: 0.3rem;
    transition: fill 0.15s ease-in-out;
    cursor: pointer;

    &:hover {
      color: #bb392d;
    }

    &:focus {
      color: #c82f21;
    }
  }
}

.event-facet {
  position: absolute;
  width: 6px;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #F7941D;
}

.fc { /* the calendar root */
  //max-width: 1100px;
  margin: 0 auto;
}

.fc-event {
  border: none;
  overflow: hidden;
}

//.fc-timegrid-slot {
//  height: 2.5em !important;
//}

.fc .fc-timegrid-slot-minor {
  border-top-style: none;
}

:root {
  --fc-today-bg-color: white
}

#external-events {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.events {
  position: relative;
  border-radius: 4px;

  &:nth-child(1n) {
    margin-right: 7px
  }

  font-size: 16px;
  margin-bottom: 7px;
  display: flex;
  flex-direction: column;
  align-items: start;
  cursor: pointer;
  padding: 5px 10px;
  width: 100%;

  .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  @media (max-width: map.get($breakpoints, "md")) {
    font-size: 14px;  
  }
}

.accordionItem {
  --bs-accordion-bg: #F9F9FC;
  --bs-accordion-active-color: #3F3F55;
  --bs-accordion-active-bg: #F9F9FC;

  .accordion-body {
    padding-left: 0 !important;
  }


  button {
    padding-left: 0 !important;
    border-bottom: 1px solid #EFEFF5 !important;
    box-shadow: none !important;
    color: #141C35 !important;
  }
}

.select__input {
  height: 30px;
}

.select__indicator-separator {
  display: none;
}

.schedulesSelect__control {
  border: 1px solid #D9D9D9;
  border-radius: 10px!important;
  color: #3F3F55;
}

.schedulesSelect__indicator-separator {
  display: none;
}
.schedulesSelect__menu {
  z-index: 10!important;
}
.schedulesSelect__input {
  height: 42px;
}
.dropDownButton {
  margin-bottom: 15px;
  width: 150px;

  &.show {
    button {
      &::after {
        transform: none;
        top: 8px;
      }
    }
  }

  button {
    padding: 8px 0px 8px 14px !important;
    width: 150px;
    height: 40px;
    color: #141C35 !important;
    background: #FFFFFF !important;
    border: 1px solid #EFEFF5 !important;
    border-radius: 10px;
    text-align: left;

    &:hover, &:active, &:focus {
      box-shadow: none !important;
    }

    &::after {
      border: none;
      position: absolute;
      right: 10px;
      content: "\2303";
      transform: rotate(180deg);
      top: 0;
      font-size: 22px;
    }
  }
}


