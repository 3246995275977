@import 'variables';

.auth-form{
  width: 100%;

  .goBack {
    margin-bottom: 15px;
    cursor: pointer;
    color: $main_color;
    font-weight: 600;
    font-size: 16px;
    span {
      margin-left: 10px;
    }
  }

  .resentCode {
    color: #979AA8;
    font-weight: 600;
    font-size: 16px;
    
    cursor: pointer;
  }

 .password {
    position: relative;
  
    .iconEye {
      position: absolute;
      right: 15px;
      top: 10px;
    }
  }

  input {
    display: block;
    width: 100%;
    height: 50px;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
  }

  .inputsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .oneInput {
    width: 500px;
  }

  .submit {
    padding-left: 0px;
  }

  label {
    display: block;
    font-weight: 600;
    font-size: 16px;
    color: $text_2_color;
  }

  .checkbox-wrapper {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    a {
      text-decoration: none;
    }

    input {
      width: 20px;
      height: 50px;
      border-radius: 3px;
      margin-top: 10px; 
      margin-right: 15px;
    }

    span {
      color: $main_color;
    }
  }
 
    
  .submit {
    display: inline;
    background: $main_color;;
    border-radius: 10px;
    width: 230px;
    height: 54px;
    margin-top: 48px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .code {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .wrapperCode {
    width: 30%;
    text-align: center;
  
    input {
      display: block;
      width: 30%;
      margin-top: 5px;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}

.spinner-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.auth-form__answer {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
}

.forgotPassword {
  font-weight: 600;
  font-size: 18px;
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: center;

  a {
    color: #979AA8;
    text-decoration-style:dashed;
  }
}


@media (max-width: 961px) and (max-height: 601px)  {
  .auth-form {
    label {
      font-size: 11px;
    }
  }

  .checkbox-wrapper {
      width: 15px;
  }
}


@media (max-width: 1680px) and (max-height: 1050px)  {
  .auth-form {
    .submit {
      margin-top: 0px;
    }
  }
}

@media (max-width: 1280px) and (max-height: 1024px) {
  .auth-form {
    input {
      height: 40px;
    }

    label {
      font-size: 15px;
    }

    .submit {
      margin-top: 35px;
    }

    &__answer {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  .checkbox-wrapper {
    width: 15px;
  }
}


@media (max-width: 1680px) {
  .auth-form {
    .submit {
      margin-top: 5px;
    }
  }
}

@media (max-width: 991px) {
  .auth-form {
    .submit {
      margin-top: 10px;
    }

    font-size: 15px;
  }

  .auth-form {
    .submit {
      margin-top: 5px;
      width: 185px;
      height: 45px;
      font-size: 15px;
    }
  }
}

@media(max-width: 665px) {
  .auth-form{
    .oneInput {
      width: 100%;
    }
  }
}