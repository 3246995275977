@import 'variables';
.editButton {
    width: 220px;
    height: 55px;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    color: #3F3F55;
    font-weight: 500;
    font-size: 18px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 0.25rem!important;
    margin-bottom: 0.25rem!important;
    
    p {
        margin: 0;

        b {
            white-space: nowrap;
        }
    }

    svg {
        width: 30px;
        height: 30px;
    }
}

.buttons {
    display: flex;
    flex-wrap: wrap;
}

.goBack {
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    span {
        margin-left: 10px;
    }
   }




.calendar21 {
    table  tbody tr{
        height: 21px;
        
    }
}

.calendar32 {
    table  tbody tr{
        height: 32px;
        
    }
}

.scaleButton {
    width: 200px;
    padding: 0 5px;
}