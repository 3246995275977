.profile {
    font-weight: 600;
    font-size: 16px;
    color: #3F3F55;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    //height: 85vh;
    //max-height: 80vh;
    //border: 2px solid black;
   
    width: 100%;
    .info {
       margin-bottom: 20px; 
    }
    .title {
        font-size: 20px;
    }

    input {
        font-weight: 400;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .submit {
            width: 112px;
            height: 40px;
            background: #E9EDFF;
            border-radius: 10px;
            color: #5882FF;
            border: none;
        }
    }

    
}

.centerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height:200px;
}

.answerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
    font-size: 20px;
}

.submit {
    background: #E9EDFF;
    border-radius: 10px;
    color: #5882FF;
    border: none;
    width: 100%;
    height: 50px;
}