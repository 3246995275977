.subscriptions{
    padding: 25px;
    background: #F9F9FC;
    width: 100%;
    height: 85%;
    max-height: calc(100vh - 25px);
    .overlay {
        width: 100%;
        height: 100%;
        background: white;
        border: 1px solid #D9D9D9;
        border-radius: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 40px;
        padding-right: 40px;
        overflow: auto;
        display: flex;
    

    }
    .subscription {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.centerWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}