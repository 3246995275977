@import 'variables';
@import 'mixin';

.form {
    width: 100%;
}

.submit {
    @include submit;
    @include horizontalAlign;
    margin-top: 10px;   
}

.submitDelete {
    @include submit;
    background: $forth_color;
} 


.spinnerWrapper {
    @include spinnerWrapper;
}

.formAnswer {
    @include formAnswer;
}

label {
    @include label;
}

input {
    @include input
}

.length {
    display: flex;
    input {
      padding-left: 10px;
    }
    label {
        color: rgba($color: $text_2_color, $alpha: 0.7);
    }
}

.lengthItem {
    width: 30%;
    margin-top: 10px;
    &:last-child {
        margin-left: 5%;
    }

}
.add {
    color: $main_color;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
   div{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
   }
}

.buttons {
    @include formButtonsChange;
    button{
        border: none
    }
}

.checkbox {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    input {
        width: 20px;
        height: 50px;
        border-radius: 3px;
        margin-top: 10px; 
        margin-right: 15px;
    }

 }

 .confirm {
    font-size: 20px;
    margin-top: 10px;
}