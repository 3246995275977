@import "variables";


.menu {
    height: 100%;
    width: 100%;
    min-height: 100vh;
    background: $background_color;
    border-right: 1px solid #D9D9D9;
    text-align: center;
    padding-left: 10% ;
    padding-right: 10%;

}

.activeMenu {
    position:relative;
    display: block;
    left: 0px;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
 
 }

.menuItem {
     display: flex;
     align-items: center;
     justify-content: space-between;
     color: $text_1_color;
     font-weight: 500;
     font-size: 18px;
     cursor: pointer;
     position: relative;
     padding-bottom: 15px;
     padding-top: 15px;
     div:nth-child(2) {
        margin-left: 15px;
     }
     width: 100%;
     height: 100%;

     div {
        display: flex;
     }
 }

.activeMenuItem {
    color: $main_color;
 }

 .point {
    width: 10px;
    height: 10px;
    background: $main_color;
    border-radius: 100%;
}


.logo {
    width: 51px;
    height: 69px;
    margin: 51px 0 85px 0;
}



.icon * {
    stroke: #86868D;
}

.activeIcon * {
    stroke: #5882FF;
}


 @media(max-width: 1300px) {

    .point {
        width: 5px;
        height: 5px;
    
    }

    .menuItem {
        font-size: 17px;
    }
   

   .logo {
       width: 45px;
       height: 55px;
   }
   
   .icon {
    width: 20px;
    height: 20px;
   }


} 

@media(max-width: 990px) {
    .menu {
        display: none;
        position:absolute;
        top: 0;
        left: -300px;
        z-index: 3;
        height: 100%;
        width: 300px;
        min-height: 100vh;
        background: rgba(242,243,249, 0.9);
        border-right: 1px solid #D9D9D9;
        text-align: center;
        padding-left: 25px ;
        padding-right: 25px;
      
    }
    
    .activeMenu {
        display: block;
        left: 0px;
    }

    .point {
        width: 10px;
        height: 10px;
    
    }

    .menuItem {
        font-size: 20px;
    }
   
    .icon {
    width: 30px;
    height: 30px;
    }

}  
