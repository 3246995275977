$main_color: #5882ff;
$text_1_color: #3f3f55;
$text_2_color: #213159;
$text_3_color: #979aa8;

$line_1_color: #d9d9d9;
$line_2_color: #f1f1f1;
$hover_color: #e9edff;
$icon_color: #86868d;

$first_color: #8cc63e;
$second_color: #f7941d;
$third_color: #1dc0f0;
$forth_color: #ed217d;

$background_color: #f2f3f9;
$background_2_color: #f9f9fc;

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1536px
);
