.editButton {
    max-width: 220px;
    width: 100%;
    min-height: 55px;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    color: #3F3F55;
    font-weight: 500;
    font-size: 18px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 0.25rem!important;
    margin-bottom: 0.25rem!important; 
    padding: 0 20px;
    

}