.terms {
    padding-top: 30px;
    min-height: 100vh;
    //padding-bottom: 50px;
    li {
        font-size: 24px;
    }
    .header {
        text-align: center;
        margin-bottom: 15px;
        div {
            font-size: 26px;
            text-align:start
        }
    }
    h1 {
        margin-bottom: 30px;
        
    }
    
}