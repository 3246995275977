.tableContainer {
  table {
    overflow: hidden;
    box-shadow: 0 0 0 1px #D9D9D9;
    border-radius: 10px;
    background: #FFFFFF;

    td {
      padding: 14px;
    }

    td, th {
    }

    td, th {
      text-align: center;

      &:first-child {
        padding-left: 30px;
        padding-right: 0;
      }

      &:last-child {

        padding-right: 15px;
        padding-left: 0;
      }
    }
  }

  thead {
    background-color: #F2F3F9;
  }

  tbody {
    tr {
      cursor: pointer;

      &:hover {
        background-color: #E9EDFF;
      }

      &:last-child {
        border-bottom: 0px solid white;
      }
    }
  }

}
