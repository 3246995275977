@import 'variables';

.wrapper {
    width: 100%;
    height: 100%;
    font-family: "OpenSans";
    font-style: normal;
    font-weight: 400;
    color: $text_1_color;
    overflow-y: auto;
    overflow-x: hidden; 
}
.left-part{
    //border: 2px solid red;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh; 
    //padding-right: 10%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.right-part{
    height: 100%;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: block;
    //border: 2px solid black;
    background:  url("../../assets/img/auth_background.jpg");
    background-size: cover;
    background-repeat: no-repeat;

}


.main {
    width: 100%;
    //height: 95%;
    max-height: 95%;
     max-height: 95vh;
    min-height: 95vh; 
    //border: 2px solid orange;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;

}


.logo {
    width: 51px;
    height: 69px;
}

.logo-text {
    color: $text_2_color;
    font-family: 'LeagueSpartan';
    font-weight: 700;
    font-size: 24.9474px;
}


.header {
    //border: 2px solid red;
    width: 55%;
    text-align: center;
    height: 150px;
}



.content{
    //border: 2px solid rgb(0, 255, 115);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    width: 100%;
    height: calc(100% - 150px);
}



.form {
    margin-top: 30px;
    width: 100%;
    max-height: 60vh;
    min-height: 60vh;
}




.subtitle, .title {
    text-align: center;
    width: 100%;
}

.title {
    font-family: 'LeagueSpartan';
    font-weight: 500;
    font-size: 48px;
}

.subtitle {
    font-size: 18px;
}


.footer {
 position: absolute;
 bottom: 0px;
 a {
    text-decoration: none;
 }
}


 @media (max-width: 1680px)  {
    .wrapper {
        .header {
            width: 90%;
        }
      /*   .main {
            height: 83%;
        } */

        .content {
            width: 90%;
        }


        .title {
            font-size: 35px;
        }

        .logo {
            width: 35px;
            height: 55px;
        }

        .form {
            margin-top: 0px;
            //height: 85%;
        }

    }
} 
 

.textWrapper {
    //border: 1px solid orange;
    font-family: 'LeagueSpartan';
    color: #3F3F55;
    font-weight: 400;
    font-size: 18px;
    width: 100%;
    overflow: hidden;
    vertical-align: bottom;
    display: flex;
    flex-direction: column;
    justify-content: end;
    text-align: center;
    div:first-child {
        font-weight: 500;
        font-size: 48px;
    }
}



 @media (max-width: 991px) {
    .right-part {
        display: none; 
    }
  /*   .wrapper{
        font-size: 11px;
        .right-part {
            display: none; 
        }
        .logo {
            width: 15px;
            height: 25px;
        }
        .logo-text {
            font-size: 17;
        }
        .header {
            position: relative;
            top: 0;
            //height: 11%;
        }
        .title {
            font-size: 25px;
            //height: 13%;
        }
     
    } */
} 

@media (max-height: 811px)  {
    .footer {
        position: relative;
        bottom: 0;
    }
}



@media (max-height: 765px)  {
    .form {
        max-height: 75%;
    }
}