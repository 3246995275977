@import 'variables';
@import 'mixin';

.form {
    width: 100%;

}

.submit {
    @include submit;
    @include horizontalAlign;
    margin-top: 10px;   
}

.submitDelete {
    @include submit;
    background: $forth_color;
} 


.spinnerWrapper {
    @include spinnerWrapper;
}

.formAnswer {
    @include formAnswer;
}

label {
    @include label;
}

input {
    @include input
}


.confirm {
    font-size: 20px;
    margin-top: 10px;
}

.buttons {
    @include formButtonsChange;

    button {
        border: none
    }
}

.confirm {
    font-size: 20px;
    margin-top: 10px;
}

