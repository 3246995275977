@import 'variables';
@import 'mixin';

.form {
    width: 100%;
}

.submit {
    @include submit;
    @include horizontalAlign;
    margin-top: 10px;   
}

.submitDelete {
    @include submit;
    background: $forth_color;
} 

.buttons {
    @include formButtonsChange;

    button {
        border: none
    }
}

.spinnerWrapper {
    @include spinnerWrapper;
}

.formAnswer {
    @include formAnswer;
}

label {
    @include label;
}

input {
    @include input;
    margin-bottom: 10px;
}

.confirm {
    font-size: 20px;
    margin-top: 10px;
}

button {
    border: none
}

.colorPicker {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .color {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
}