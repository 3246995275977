.wrapper {
    min-height: 100vh; 
    position: relative;
    overflow: hidden;
}

.main {
    width: 100%;
    height: 100%;
}

.menuBurger {
    display: none;
    height: 50px;
    margin-right: 20px;  
        img {
            display: block;
            cursor: pointer;
            width: 40px;
            height: 40px;
            opacity: 0.8;
  }
}


@media (max-width: 990px) {
    .menuBurger {
        display: flex;
        align-items: center;
        justify-content: end;
    }
}

.centerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}