.terms {
    padding-top: 30px;
    //padding-bottom: 50px;
    min-height: 100vh;
    li {
        font-size: 24px;
    }
    .header {
        text-align: center;

        margin-bottom: 30px;
    }
    h2 {
        font-size: 26px;
    }
}