.mainContainer {
  height: 100%;
  padding: 17px 28px;
  background-color: #F9F9FC;
  box-sizing: border-box;
}

.mainHeader {
  padding: 23px 50px;
  background: #F9F9FC;
  border-bottom: 1px solid #D9D9D9;
  width: 100%;
  //font-family: 'Open Sans',"sans-serif";
  font-weight: 500;
  font-size: 32px;
  color: #3F3F55;
}

.btn-check:checked +.btn {
  background: #E9EDFF!important;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  text-align: center;
  color: #5882FF!important;
}

.select__multi-value__label, .select__multi-value__remove  {
  display: flex;
  align-items: center;
  height: 33px;
  background: #E9EDFF;
  border: 1px solid #5882FF;
  color: #5882FF !important;
  vertical-align: middle

}

.select__multi-value__remove  {
  width: 30px;
  border-left: none;
  border-radius: 0 5px 5px 0 !important;
}

.select__multi-value__label {
  border-right: none;
  border-radius: 5px 0 0 5px !important;
}

.centerWrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}




