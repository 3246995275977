.wrapperSubscription {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    height: 649px;
    max-width: 481px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 32px;
    background: linear-gradient(157.19deg, #d2dbff 8.55%, #5882FF 82.52%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 24px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-left: 2%;
    margin-right: 2%;
    &__info {
        font-size: 20px; 
        padding-left: 20px;
        padding-right: 20px;
        height: 450px;
        min-height: 300px;
    }
    .payment {
        text-align: center;
        margin-left: 15px;
        margin-right: 15px;
        button {
            background: #FFFFFF;
            border-radius: 10px;
            width: 60%;
            height: 50px;
            margin-left: -32px;
            border: none;
            color: #3F3F55;
            font-weight: 600;
            font-size: 18px;
        }
        div {
            margin-left: -32px;
            font-weight: 700;
            font-size: 18px;
        }
    }

    .header{
        font-size: 36px; 
    }
    .coast {
        font-size: 24px;
        span {
            font-weight: 400;
            font-size: 18px;
        }
    }
    ul {
        font-weight: 400;
       list-style-type: disc;
       padding-left: 32px;
        span {
            font-weight: 600;
            font-size: 24px; 
       
        }
    }

    .infoList {
        font-weight: 400;
        list-style-type:none;
        padding-left: 0px;
        span {
            font-weight: 600;
            font-size: 24px;
        }  
    }

    .topLine {
        position: absolute;
        width: 100%;
        height: 15px;
        top: 0px;
        left: 0px;
        background: #5882FF;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

    }

    .bottomLines {
        position: absolute;
        left: 0;
        bottom: 0;
        width:fit-content;
        z-index: -1; 
    }

    &Orange {
        background: linear-gradient(157.19deg, #FFC56A 8.55%, #FEA413 82.52%);
        .bottomLines {
            g:first-child path {
                fill: #DE9115
            }
            g:nth-child(2) path {
                fill: #FFC66B
            } 
        }

        .topLine {
            background:  #F7941D;
        }
    }
    
    &Green {
        background: linear-gradient(144.49deg, #AAED51 6.89%, #81BC32 73.38%), #F9F9FC;
        .bottomLines {
            g:first-child path {
                fill: #6DA522
            }
            g:nth-child(2) path {
                fill: #A7D469
            } 
        }

        .topLine {
            background:  #8CC63E;
        }
    }
    &Magenta {
        background: linear-gradient(144.49deg, #FF98C6 6.89%, #ED217D 73.38%), #F9F9FC;
        .bottomLines {
            g:first-child path {
                fill: #CF377C
            }
            g:nth-child(2) path {
                fill: #FE94C4
            }
        }

        .topLine {
            background:  #ED217D;
        }
    }
 

    }


     @media (max-width: 1320px) {
        .wrapperSubscription {
            padding-left: 10%;
            font-size: 20px;
            ul {
                list-style-type: none;
                padding-left: 0px;
            }

            .header{
                font-size: 27px; 
            }

            .payment {
                text-align: center;
                button {
                    width: 90%;
                }

        }
    }
}

    @media (max-width: 991px)  {
        .center {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }

        .wrapperSubscription {
            width: 481px;
            padding-left: 32px;
            font-size: 24px;
            ul {
                list-style-type: disc;
                padding-left: 32px;
            }

            .header{
                font-size: 36px; 
            }
        } 

    }


    @media (max-width: 612px)  {
        .center {
            display: block;
        }

        .wrapperSubscription {
            width: 90%;
        } 

    }