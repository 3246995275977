@import 'mixin';
@import 'variables';




.settings {
    padding: 25px;
    background: #F9F9FC;
    width: 100%;
    height: 85%;
    .overlay {
        width: 100%;
        height: 100%;
        background: white;
        border: 1px solid #D9D9D9;
        border-radius: 10px;
        padding-top: 45px;
        padding-left: 39px;

    }
    .button {
        @include submit;
        border: none;
    }

    a { 
        text-decoration: none;
        display: block;
        width: 100%;
        height: 100%;
    }
    
    tr:nth-child(2), tr:nth-child(3) {
        .button {
            background-color: $hover_color ;
            color: $main_color !important;
    
            border-color: $hover_color;
        }
    }
    
    tr:last-child {
        .button {
            background-color: $forth-color;
            border-color: $forth-color;
        }
        
    }
    .description {
        font-weight: 600;
        font-size: 18px;
        div:nth-child(2) {
            font-weight: 400;
            font-size: 16px;
        } 
        .button {
            margin-top: 15px;
            margin-bottom: 5px;
            display: none;
        }
        a {
            display: none;
        }
    }

    td:last-child {
        text-align: center;
    }
    
}


@media (max-width: 990px)  {
    .settings {
        .description {
            font-weight: 600;
            font-size: 18px;
            .button {
                display: inline;
            }
            a {
                display: block;
            }
        }
        td:last-child {
            display: none;
        }
    }



}

@media (max-width: 560px) {
    .settings {
        tr {
            text-align: center;
        }
        .description {
            .button {
                width: 70%;
            }

        }
    }

}