@import "variables";
@import "mixin";

.form {
	width: 100%;

	.scheduleSelect {
		> div {
			width: 100%;
			min-height: 50px;
			border-radius: 10px;
			background: #f9f9fc;
		}
	}

	.checkbox {
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;

		input {
			width: 20px;
			height: 50px;
			border-radius: 3px;
			margin-top: 10px;
			margin-right: 15px;
		}
	}
}

.submit {
	@include submit;
	@include horizontalAlign;
	margin-top: 10px;
}

.buttons {
	@include formButtonsChange;
}

.spinnerWrapper {
	@include spinnerWrapper;
}

.formAnswer {
	@include formAnswer;
}

label {
	@include label;
}

input {
	@include input;
}

///
.availability {
	display: flex;
	input {
		width: 57px;
		height: 50px;
		border-radius: 0px;
		border: 1px solid #d9d9d9;
		background-color: #f9f9fc;
		color: $text_3_color;
		&:first-child {
			border-bottom-left-radius: 10px;
			border-top-left-radius: 10px;
		}
		&:last-child {
			border-bottom-right-radius: 10px;
			border-top-right-radius: 10px;
		}
	}

	.activeDay {
		background-color: #5882ff;
		color: #ffffff;
	}
}

.time {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.timeItem {
		width: 45%;
		margin-left: 15px;
		margin-right: 15px;
		&:nth-child(2) {
			width: 15px;
			height: 0px;
			border: 2px solid $text_3_color;
			border-radius: 5%;
		}
	}
}

.wrapperAvailability {
	margin-top: 10px;
	label {
		margin-bottom: 3px;
	}
}

.availabilityFooter {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-top: 18px;
	color: $main_color;
	cursor: pointer;
	.deleteText {
		color: $forth_color;
	}
}

.add {
	color: $main_color;
	font-weight: 600;
	font-size: 16px;
	cursor: pointer;
	div {
		margin-top: 5px;
		margin-bottom: 5px;
	}
}
