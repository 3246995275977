

@import "variables";

.overlay {
    font-size: 16px;
    color: $text_1_color;
    background: rgba($color: #000000, $alpha: 0.4);
    position: absolute;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0 ;
    z-index: 5;
}


.window {
    min-width: 524px;
    min-height: 220px;    
    max-height: 90%;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 56px 70px 30px 70px;
    //overflow: auto;


}
.title {
    font-weight: 600;
    font-size: 28px;
    color: #3F3F55;
    text-align: center;

}

.close {
    position: absolute;
    top: 15px;
    right: 10px;
    width: 44px;
    height: 44px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div:first-child {
        transform: rotate(-45deg);
        margin-top: -2px;
    }
    div:nth-child(2) {
        transform: rotate(45deg);
        margin-top: -2px;
    }
    div {
        background: $text_3_color;
        height: 2px;
        width: 30px;
    }

}

