/* Inter */
@font-face {
    font-family: 'Inter';
    src: url('Inter/Inter-Regular.ttf');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'Inter';
    src: url('Inter/Inter-Medium.ttf');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: 'Inter';
    src: url('Inter/Inter-SemiBold.ttf');
    font-weight: 600;
    font-style: normal
}

/* LeagueSpartan */
@font-face {
    font-family: 'LeagueSpartan';
    src: url('League_Spartan/LeagueSpartan-Medium.ttf');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: 'LeagueSpartan';
    src: url('League_Spartan/LeagueSpartan-SemiBold.ttf');
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: 'LeagueSpartan';
    src: url('League_Spartan/LeagueSpartan-Bold.ttf');
    font-weight: 700;
    font-style: normal
}

/* OpenSans */
@font-face {
    font-family: 'OpenSans';
    src: url('Open_Sans/OpenSans-Regular.ttf');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'OpenSans';
    src: url('Open_Sans/OpenSans-Medium.ttf');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: 'OpenSans';
    src: url('Open_Sans/OpenSans-SemiBold.ttf');
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: 'OpenSans';
    src: url('Open_Sans/OpenSans-Bold.ttf');
    font-weight: 700;
    font-style: normal
}
