.tableContainer {
  table {
    overflow: hidden;
    box-shadow: 0 0 0 1px #D9D9D9;
    border-radius: 0px 0px 10px 10px;
    background: #FFFFFF;

    td {
      padding: 14px;
    }

    td, th {
      text-align: center;

      &:first-child {
        padding-left: 30px;
        padding-right: 0;
      }

      &:last-child {

        padding-right: 15px;
        padding-left: 0;
      }
    }


    .color {
      display: flex;
      align-items: center;
      justify-content: center;
      div:first-child {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        border-radius: 100%;
      } 
    }
  }

  thead {
    background-color: #F2F3F9;
  }

  tbody {
    tr {
      cursor: pointer;

      &:hover {
        background-color: #E9EDFF;
      }

      &:last-child {
        border-bottom: 0px solid white;
      }
    }
  }
}

.additionalHead {
  color:#979AA8;
  background-color: #FFFFFF;;
  display: flex;
  box-shadow: 0 0 0 1px #d9d9d9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}



.tableWithTabs {
  box-shadow: 0 0 0 1px #D9D9D9;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.addBtn {
  text-decoration: none;
  margin-left: auto;
  margin-right: 30px;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  box-shadow: 0 0 0 1px #D9D9D9;
  border-radius: 0 0 10px 10px;
  background-color: #FFFFFF;;
  box-sizing: border-box;
  padding: 3%;
}

.card {
  cursor: pointer;
  background: #F9F9FC;
  border: 1px solid #5882FF;
  border-radius: 10px;
  width: 31%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin: 1%;

  &:hover {
    background: #E9EDFF;
  }
}
