
@import 'variables';
@import 'mixin';

.form {
    width: 100%;
    overflow:auto;
    overflow-x: hidden;

}

.submit {
    @include submit;
    @include horizontalAlign;
    margin-top: 10px;   
}


.buttons {
    @include formButtonsChange;
    button{
        border: none;
    }
}


.spinnerWrapper {
    @include spinnerWrapper;
}

.formAnswer {
    @include formAnswer;
}

label {
    @include label;
}

input {
    @include input
}


///
.availability {
    display: flex;
    input {
        width: 57px;
        height: 50px;
        border-radius: 0px;
        border: 1px solid #D9D9D9;
        background-color: #F9F9FC;
        color: $text_3_color;
        padding-left: 0;
        &:first-child {
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
        }
        &:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px; 
        }
    }

    .activeDay {
        background-color: #5882FF;
        color: #FFFFFF;
    }
    
}


.time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .timeItem{
        width: 45%;  
        &:nth-child(2) {
            width: 15px;
            height: 0px;
            border: 2px solid $text_3_color;
            border-radius: 5%;
        }
        &:nth-child(4) {
            width: 20px;
        }
    }
   
}

.wrapperAvailability {
    margin-top: 10px;
    label {
        margin-bottom: 3px;
    }
}


.availabilityFooter {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 18px;
    color: $main_color;
    cursor: pointer;
    .deleteText {
        color: $forth_color;
    }
}

.add {
    color: $main_color;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
   div{
    margin-top: 5px;
    margin-bottom: 5px;
   }

}

.confirm {
    font-size: 20px;
    margin-top: 10px;
}


.close {
    width: 20px;
    height: 20px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div:first-child {
        transform: rotate(-45deg);
        margin-top: -2px;
    }
    div:nth-child(2) {
        transform: rotate(45deg);
        margin-top: -2px;
    }
    div {
        background: $text_3_color;
        height: 2px;
        width: 15px;
    }

}