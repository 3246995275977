.tabsContainer {
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  margin: 0 0 10px 0 ;
}

