@import 'mixin';

.header {
    display: flex;
    justify-content: space-between;
    .button {
       @include submit ;
       border: none;
    }

    .leftPart {
        span{
            margin-left: 24px;
        }
    }

}
.spinnerWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.editButton {
    width: 160.78px;
    height: 58px;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    color: #3F3F55;
    font-weight: 600;
    font-size: 18px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-around;


}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
