.radioButton {
  font-weight: 600;
  font-size: 16px;
  color: #3F3F55;
  border: none;
  margin: 8px 6px;
  padding: 8px 10px;
}
@media (max-width: 990px) {
  .radioButton {
    font-size: 14px!important;
    margin: 8px 10px ;
    padding: 8px 12px ;
  }
}
